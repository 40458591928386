<template>
  <div class="container photo px-0 py-0">
    <v-row v-if="attachments && attachments.length > 0 && thumbnailMainImage">
      <v-col cols="12" sm="12">
        <div class="d-flex justify-content-end my-1">
          <b-icon
            v-if="isHiddenForBrandPhotosPortal == false"
            class="mx-1"
            :icon="isHiddenForBrandPhotosPortal == true ? `eye` : `eye-slash`"
            font-scale="2"
            size="lg"
          ></b-icon>
          <b-icon
            v-if="isWarningPhotosPortal !== null && isWarningPhotosPortal !== 0"
            class="mx-1"
            icon="exclamation-triangle-fill"
            font-scale="2"
            :variant="isWarningPhotosPortal == true ? `danger` : `success`"
          ></b-icon>
        </div>
        <div class="my-1 d-flex justify-content-center">
          <img
            class="thumbnail_image"
            :src="thumbnailMainImage"
            alt=""
            @error="onImageError"
            @click="onClickThumbnail(currentProOrMatId, question.id)"
          />
        </div>
      </v-col>
      <DialogPhotoDetail
        :ref="`dialogPhotoDetail_${currentProOrMatId}_${question.id}`"
        :worksessionPosBrandId="worksessionPosBrandId"
        :surveyListItem="surveyListItem"
        :component="component"
        :proOrMat="proOrMat"
        :question="question"
        :assignTypes="assignTypes"
        :currentProOrMatId="currentProOrMatId"
        :commentsPhotosPortal="commentsPhotosPortal"
        :punctuationLabel="punctuationLabel"
        :worksessionSurveyPhotosPortal="worksessionSurveyPhotosPortal"
        :attachments="attachments"
        :onUploadAttachment="onUploadAttachment"
        :onClickUploadAttachment="onClickUploadAttachment"
        @deleteAttachment="delId => deleteAttachment(delId)"
        @onClickSaveComments="saveData => onClickSaveComments(saveData)"
      />
    </v-row>
    <v-row
      v-show="
        (!attachments || (attachments && !attachments.length)) &&
          !readOnlyInputs &&
          isShowCamera
      "
    >
      <v-col cols="12" sm="12">
        <gennera-picture-input
          :crop="false"
          class="my-gennera-picture-input"
          :ref="`surveyPhotoInstance_${currentProOrMatId}_${question.id}`"
          size="5"
          width="500"
          accept="image/jpeg,image/png"
          button-class="btn btn-outline-info"
          aspect="Landscape"
          :customStrings="{
            drag: `Selecciona una foto`,
            upload: this.$t('uploading_image'),
            change: this.$t('change'),
            tap: 'toma una foto'
          }"
          @change="onPictureSurveyPhoto"
          :capture="`camera`"
        >
        </gennera-picture-input>
      </v-col>
    </v-row>
    <v-row v-show="!attachments || (attachments && !attachments.length)">
      <v-col cols="12" sm="12" class="text-center">
        <v-btn
          color="primary"
          dark
          :loading="isSelecting"
          @click="onUploadAttachment"
          v-show="!readOnlyInputs && isShowGallery"
          class="btn_select_images"
        >
          {{ buttonText }}
        </v-btn>
        <input
          ref="uploaderPhotos"
          class="d-none"
          type="file"
          accept=".jpg,.png"
          @change="onFileChanged"
        />
      </v-col>
    </v-row>
    <v-row v-if="worksessionSurveyPhotosPortal">
      <v-col cols="12" sm="12">
        <div class="mt-2">
          <h5 class="text--primary">{{ punctuationLabel }}</h5>
          <div
            class="mt-2 text--primary"
            v-if="commentsPhotosPortal.length > 0"
          >
            <h5 class="text--primary">{{ $t("comments") }}</h5>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ul>
                  <li
                    :key="`photo_comment_${index}`"
                    v-for="(commentItem, index) in commentsPhotosPortal"
                  >
                    {{ commentItem.label }}: {{ commentItem.text }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// vue-web-cam demo app -
// https://github.com/VinceG/vue-web-cam/blob/master/demo/src/main.vue
import webCamera from "@/components/webCamera.vue";
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import DialogPhotoDetail from "./photodetails/DialogPhotoDetail.vue";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  props: [
    "value",
    "worksessionPosBrandId",
    "readOnlyInputs",
    "photoType",
    "attachments",
    "surveyListItem",
    "component",
    "proOrMat",
    "question",
    "assignTypes"
  ],
  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Select ...",
      newAttachedPhotos: []
    };
  },
  components: {
    webCamera,
    DialogPhotoDetail,
    GenneraPictureInput
  },
  watch: {
    attachments(newVal, oldVal) {
      console.log("attachments newVal, oldVal - ", newVal, oldVal);
      if (oldVal.length === 0 && newVal.length > 0) {
        this.$nextTick(() => {
          this.onClickThumbnail(this.currentProOrMatId, this.question.id);
        });
      }
    }
  },
  computed: {
    ...mapGetters("myroute", ["deviceInformation"]),
    worksessionSurveyPhotosPortal() {
      let ret = null;
      if (this.question && this.question?.worksession_survey_photos_portal) {
        ret = this.question.worksession_survey_photos_portal;
      }
      return ret;
    },
    isHiddenForBrandPhotosPortal() {
      let ret = null;
      if (this.worksessionSurveyPhotosPortal) {
        const { isHiddenForBrand } = this.worksessionSurveyPhotosPortal;
        ret = isHiddenForBrand;
      }
      return ret;
    },
    isWarningPhotosPortal() {
      let ret = null;
      if (this.worksessionSurveyPhotosPortal) {
        const { isWarning } = this.worksessionSurveyPhotosPortal;
        ret = isWarning;
      } else {
        if (
          this.question?.foundLastResponseSurveyQuestionItem &&
          this.question?.foundLastResponseSurveyQuestionItem
            ?.worksession_survey_photos_portal
        ) {
          const {
            isWarning
          } = this.question?.foundLastResponseSurveyQuestionItem?.worksession_survey_photos_portal;
          ret = isWarning;
        }
      }
      return ret;
    },
    commentsPhotosPortal() {
      let ret = [];
      if (this.worksessionSurveyPhotosPortal) {
        const {
          commentsAdmin,
          scoreAdminBy,
          isCommentsAdmin,
          ppScoreAdminBy,
          commentsBrand,
          scoreBrandBy,
          isCommentsBrand,
          ppScoreBrandBy,
          commentsGPV,
          scoreGPVBy,
          isCommentsGPV,
          ppScoreGPVBy
        } = this.worksessionSurveyPhotosPortal;
        if (ppScoreAdminBy && commentsAdmin !== null) {
          ret.push({
            label: "Admin",
            text: commentsAdmin
          });
        }
        if (ppScoreBrandBy && commentsBrand !== null) {
          ret.push({
            label: "Marca",
            text: commentsBrand
          });
        }
        if (ppScoreGPVBy && commentsGPV !== null) {
          ret.push({
            label: "GPV",
            text: commentsGPV
          });
        }
      }
      return ret;
    },
    punctuationLabel() {
      let str = ``;
      if (this.punctuationValue !== null) {
        return `Punctuation: ${this.punctuationValue} / 5`;
      }
      return str;
    },
    punctuationValue() {
      let ret = null;
      if (this.worksessionSurveyPhotosPortal) {
        const {
          scoreGPV,
          scoreGPVBy,
          ppScoreGPVBy,
          scoreBrand,
          scoreBrandBy,
          ppScoreBrandBy,
          scoreAdmin,
          scoreAdminBy,
          ppScoreAdminBy
        } = this.worksessionSurveyPhotosPortal;
        if (ppScoreBrandBy && scoreBrand !== null && !isNaN(scoreBrand)) {
          return scoreBrand;
        } else if (
          ppScoreAdminBy &&
          scoreAdmin !== null &&
          !isNaN(scoreAdmin)
        ) {
          return scoreAdmin;
        } else if (ppScoreGPVBy && scoreGPV !== null && !isNaN(scoreGPV)) {
          return scoreGPV;
        }
      }
      return ret;
    },
    currentProOrMatId() {
      let proOrMatId = null;
      if (this.proOrMat && this.proOrMat?.id) proOrMatId = this.proOrMat?.id;
      return proOrMatId;
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    uploadedMainImage() {
      if (this.attachments && this.attachments.length > 0) {
        const firstImageItem = this.attachments[0];
        return firstImageItem;
      }
      return null;
    },
    thumbnailMainImage() {
      const image = this.uploadedMainImage;
      if (image && image?.worksessionSurveyResponsePhoto) {
        return ApiService.getImageOrFileGlobalUri(
          image.worksessionSurveyResponsePhoto
        );
      } else if (image) {
        return URL.createObjectURL(image);
      }
      return null;
    },
    isShowCamera() {
      let ret = false;
      const isSelectedCamera = ["BOTH", "CAMERA"].includes(this.photoType);
      const isShowOnThisDevice =
        this.deviceInformation &&
        ["PC", "TABLET"].includes(this.deviceInformation?.type);

      return (ret = isSelectedCamera);
    },
    isShowGallery() {
      let ret = false;
      const isSelectedGallery = ["BOTH", "GALLERY"].includes(this.photoType);
      return (ret = isSelectedGallery);
    }
  },
  methods: {
    onImageError(event) {
      event.target.src = require('@/assets/missing.png');
      event.target.alt = "Imagen no encontrada";
    },
    onClickSaveComments(saveData) {
      this.$emit("onClickSaveComments", saveData);
    },
    deleteAttachment(delId) {
      this.$emit("deleteAttachmentItem", delId);
    },
    async onClickThumbnail(currentProOrMatId, questionId) {
      console.log(
        "currentProOrMatId, questionId - ",
        currentProOrMatId,
        questionId
      );
      this.$refs[
        `dialogPhotoDetail_${currentProOrMatId}_${questionId}`
      ].showModal(this.question, this.attachments);
      if (this.worksessionSurveyPhotosPortal) {
        const { id, isWarning } = this.worksessionSurveyPhotosPortal;
        if (isWarning) {
          // request for set isWarning to 0
          let form_data = {
            isWarning: 0,
            id
          };
          const { data } = await ApiService.post(
            `worksession/survey/update_photos_portal`,
            form_data
          );
          this.$emit("updatePhotosPortalAttributes", { isWarning: 0 });
        }
      }
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    onUploadAttachment() {
      // this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploaderPhotos.click();
    },
    onClickUploadAttachment() {
      if (this.isShowCamera) {
        this.$refs[
          `surveyPhotoInstance_${this.currentProOrMatId}_${this.question.id}`
        ].onClick();
      } else {
        this.onUploadAttachment();
      }
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files;
      if (this.selectedFile.length > 0) {
        this.newAttachedPhotos = [];
        this.newAttachedPhotos.push(this.selectedFile[0]);
        this.$emit("input", this.newAttachedPhotos);
      }
    },
    async detectedLocation() {
      let photoTaken = await this.$refs.webCameraInstance.getTakenPhotoFile();
      if (photoTaken) {
        this.newAttachedPhotos = [];
        this.newAttachedPhotos.push(photoTaken);
        this.$emit("input", this.newAttachedPhotos);
      }
    },
    onPictureSurveyPhoto() {
      const photoTaken = this.$refs[
        `surveyPhotoInstance_${this.currentProOrMatId}_${this.question.id}`
      ].file;
      if (photoTaken) {
        this.newAttachedPhotos = [];
        this.newAttachedPhotos.push(photoTaken);
        this.$emit("input", this.newAttachedPhotos);
      }
    }
  }
};
</script>

<style>
.questionbox .web-camera-container {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  padding: 0rem !important;
}
.questionbox .btn_select_images {
  width: 100%;
}
.questionbox .photo-webcamera {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}
.questionbox .photo .thumbnail_image {
  max-width: 100%;
  max-height: 150px;
  cursor: pointer;
}
</style>
