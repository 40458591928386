var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container photo px-0 py-0"},[(_vm.attachments && _vm.attachments.length > 0 && _vm.thumbnailMainImage)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"d-flex justify-content-end my-1"},[(_vm.isHiddenForBrandPhotosPortal == false)?_c('b-icon',{staticClass:"mx-1",attrs:{"icon":_vm.isHiddenForBrandPhotosPortal == true ? `eye` : `eye-slash`,"font-scale":"2","size":"lg"}}):_vm._e(),(_vm.isWarningPhotosPortal !== null && _vm.isWarningPhotosPortal !== 0)?_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"exclamation-triangle-fill","font-scale":"2","variant":_vm.isWarningPhotosPortal == true ? `danger` : `success`}}):_vm._e()],1),_c('div',{staticClass:"my-1 d-flex justify-content-center"},[_c('img',{staticClass:"thumbnail_image",attrs:{"src":_vm.thumbnailMainImage,"alt":""},on:{"error":_vm.onImageError,"click":function($event){return _vm.onClickThumbnail(_vm.currentProOrMatId, _vm.question.id)}}})])]),_c('DialogPhotoDetail',{ref:`dialogPhotoDetail_${_vm.currentProOrMatId}_${_vm.question.id}`,attrs:{"worksessionPosBrandId":_vm.worksessionPosBrandId,"surveyListItem":_vm.surveyListItem,"component":_vm.component,"proOrMat":_vm.proOrMat,"question":_vm.question,"assignTypes":_vm.assignTypes,"currentProOrMatId":_vm.currentProOrMatId,"commentsPhotosPortal":_vm.commentsPhotosPortal,"punctuationLabel":_vm.punctuationLabel,"worksessionSurveyPhotosPortal":_vm.worksessionSurveyPhotosPortal,"attachments":_vm.attachments,"onUploadAttachment":_vm.onUploadAttachment,"onClickUploadAttachment":_vm.onClickUploadAttachment},on:{"deleteAttachment":delId => _vm.deleteAttachment(delId),"onClickSaveComments":saveData => _vm.onClickSaveComments(saveData)}})],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
      (!_vm.attachments || (_vm.attachments && !_vm.attachments.length)) &&
        !_vm.readOnlyInputs &&
        _vm.isShowCamera
    ),expression:"\n      (!attachments || (attachments && !attachments.length)) &&\n        !readOnlyInputs &&\n        isShowCamera\n    "}]},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('gennera-picture-input',{ref:`surveyPhotoInstance_${_vm.currentProOrMatId}_${_vm.question.id}`,staticClass:"my-gennera-picture-input",attrs:{"crop":false,"size":"5","width":"500","accept":"image/jpeg,image/png","button-class":"btn btn-outline-info","aspect":"Landscape","customStrings":{
          drag: `Selecciona una foto`,
          upload: this.$t('uploading_image'),
          change: this.$t('change'),
          tap: 'toma una foto'
        },"capture":`camera`},on:{"change":_vm.onPictureSurveyPhoto}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.attachments || (_vm.attachments && !_vm.attachments.length)),expression:"!attachments || (attachments && !attachments.length)"}]},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readOnlyInputs && _vm.isShowGallery),expression:"!readOnlyInputs && isShowGallery"}],staticClass:"btn_select_images",attrs:{"color":"primary","dark":"","loading":_vm.isSelecting},on:{"click":_vm.onUploadAttachment}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('input',{ref:"uploaderPhotos",staticClass:"d-none",attrs:{"type":"file","accept":".jpg,.png"},on:{"change":_vm.onFileChanged}})],1)],1),(_vm.worksessionSurveyPhotosPortal)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"mt-2"},[_c('h5',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.punctuationLabel))]),(_vm.commentsPhotosPortal.length > 0)?_c('div',{staticClass:"mt-2 text--primary"},[_c('h5',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.$t("comments")))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ul',_vm._l((_vm.commentsPhotosPortal),function(commentItem,index){return _c('li',{key:`photo_comment_${index}`},[_vm._v(" "+_vm._s(commentItem.label)+": "+_vm._s(commentItem.text)+" ")])}),0)])],1)],1):_vm._e()])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }